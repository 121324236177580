import jquery from "jquery";

window.jQuery = jquery;

window.$ = jquery;



// questionaire
$(function() {
  $(document).on('change', '#use_mindbody_credit', function(e) {
    var url = $("#checkout-step-2").attr('href');
    url = url.replace('use_mindbody_credit='+!$(this).is(':checked'), 'use_mindbody_credit='+$(this).is(':checked'));

    $("#checkout-step-2").attr('href',url);
  });

   $(document).on('submit', '#survey-form', function(e) {
     var required = $('#survey-form').data('is-required');
     if(required) {
       var kind = $('#survey-form').data('kind');
       if(kind == "single-choice" || kind == "yes-no") {
         if ($('#survey-form input:radio:checked').length == 0) {
           e.preventDefault();
           alert("Bitte gebe eine Antwort ab.")
         };
       } else if (kind == "float" || kind == "freetext" || kind == "slider") {

       } else {
         if ($('#survey-form input:checkbox:checked').length == 0) {
           e.preventDefault();
           alert("Bitte gebe eine Antwort ab.")
         };
       }
     }
   });

   $(document).on('click', '#survey-form li label', function(e) {
    var li = $(this).parent('li');

    if(li.parent('ul').hasClass('sublist')) {
      li.find('textarea').toggle();

    } else {
      // set next question_id
      $('#next_question').val(li.data('nextQuestionId'));

      var kind = $('#survey-form').data('kind');

      if(kind == "single-choice" || kind == "yes-no") {
        //$('#survey-form li textarea').val('').hide();
      } else if (kind == "multiple-choice") {
        var max_answers   = parseInt($('#survey-form').data('max-answers'));
        var count_answers = $('#survey-form input:checkbox:checked').length;
        if (max_answers > 0 && count_answers >= max_answers && !li.find('input:checkbox:checked').is(':checked')) {
          e.preventDefault();
          alert("Es sind maximal "+ max_answers + " Antworten erlaubt.")
        }
      } else if (kind == "multiple-choice-with-extra") {
        li.find('.sublist').toggle();

      }

      $('#survey-form li').each(function( index ) {
        if($(this).children('input:checkbox').is(':checked')) {
          $(this).children('textarea').show();
        } else {
          $(this).children('textarea').hide();
        }
      });

      if(li.find('textarea').length > 0) {
        li.children('textarea').toggle();
      }
    }
  });


  $('.select_variant').click(function() {
    var parent_variant = $(this).closest('.variant');
    if($('#user_booking_process_variant_id').val() != $(this).data('variant-id')) {
      $('#render-selected-variant').text(parent_variant.find('.variant-title').text());
      $('.variant').removeClass('bg-green-50').addClass('bg-white');
      parent_variant.addClass('bg-green-50').removeClass('bg-white');
      $('.variant').find('.toggle-a').toggle();
      $('#user_booking_process_variant_id').val($(this).data('variant-id'));
    }
  });
})
